@import "core/index.scss";
.header-menu {
	@include font-medium;
	position: relative;
	z-index: 1;
	letter-spacing: -.02em;
	font-size: 1.3rem;

	@include media {
		display: none;
	}

	.menu-wrapper {
		height: 3.6rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.menu-item {
		display: inline-block;
		color: $color-text-light;
		transition: color $transition-hover ease;
		position: relative;
		padding: 1rem 0;

		&.placeholder {
			height: 1.4rem;
			width: 8.08rem;
			margin: 1rem 0 1rem;
		}

		+ .menu-item {
			margin-left: 1rem;
		}

		&.highlight {
			color: $color-pink;

			&:hover {
				color: darken($color-pink, $darken-ratio);
			}

			&:before {
				@include icon('deco');
				position: absolute;
				bottom: calc(100% - 1.5rem);
				left: 0;
				width: 100%;
				text-align: center;
				font-size: 2.7rem;
			}

			// &.age {
			// 	color: $color-blue;

			// 	&:hover {
			// 		color: darken($color-blue, $darken-ratio);
			// 	}

			// 	&:before {
			// 		@include icon('deco-num');
			// 	}
			// }
		}

		&:hover {
			color: $color-text;
		}
	}
}