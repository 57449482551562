@import "core/index.scss";
.header-menu {
	.header-submenu {
		position: absolute;
		left: 0;
		width: 100%;
		top: 100%;
		z-index: 2;
		background-color: $color-white;
		box-shadow: $shadow-big;
		border: .1rem solid $color-gray-light;
		border-bottom-left-radius: $radius-general;
		border-bottom-right-radius: $radius-general;
		transition: opacity $transition-hover ease, transform $transition-hover ease;
		opacity: 0;
		transform: translate3d(0, -.5rem, 0);
		padding: 2.4rem 3rem 1rem;
		overflow: hidden;

		&.show {
			opacity: 1;
			transform: translate3d(0, 0, 0);

			.submenu-wrap {
				transform: translate3d(0, 0, 0);
			}
		}

		.submenu-wrap {
			display: flex;
			transform: translate3d(0, -1rem, 0);
			transition: transform $transition-hover ease;
		}

		.submenu-left {
			@include clearFix;
			width: 50%;
		}

			.left-section {
				float: left;
				width: calc(100% / 3);
				margin-bottom: 2rem;

				&.wide {
					width: calc((100% / 3) * 2);
					height: 100%;

					.list-row {
						width: 50%;
					}
				}
			}

				.section-title {
					@include font-semibold;
					display: block;
					margin-bottom: .8rem;
				}

				.section-list {
					@include font-medium;
					color: $color-text;
					display: flex;
				}

					.list-row {
						padding-right: 2rem;
					}
				
						.row-col {
							line-height: 1.2em;
							padding: .4rem 0;
						}

							.col-link {
								transition: color $transition-hover ease;

								&:hover {
									color: $color-blue;
								}
							}

							.col-subs {
								@include font-regular;
								color: $color-text-light;
								width: 100%;
								padding: .5rem 0 .5rem 1rem;
								font-size: 1.3rem;
								line-height: 1.2em;

								li {
									padding: .3rem 0;
								}
							}

		.submenu-right {
			flex: 1 0 0;
			margin-bottom: 2rem;
			padding: 2rem;

			> * + * {
				margin-top: 2rem;
			}
		}

			.right-banners {
				display: flex;
				flex-flow: row wrap;
			}

				.banners-banner {
					width: calc(50% - 1rem);
					
					&:nth-child(2n+2) {
						margin-left: 2rem;
					}

					&:nth-child(n+3) {
						margin-top: 2rem;
					}
				}

					.banner-link {
						display: block;
					}

					.banner-imagewrap {
						position: relative;
						background-color: $color-blue-bg;
						border-radius: $radius-general;
						padding-bottom: 35.714%;
						overflow: hidden;
					}

					.banner-image {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
					}

			// .right-brands {
			// }

				.brands-title {
					display: block;
					text-align: center;
					color: $color-text-lightest;
					font-size: 1.1rem;
					text-transform: uppercase;
					line-height: 2rem;
					position: relative;

					&:before {
						@include pseudo;
						position: absolute;
						left: 0;
						right: 0;
						height: .1rem;
						background-color: $color-gray-light;
						top: 50%;
						z-index: 1;
						transform: translate3d(0, -50%, 0);
					}
					
					span {
						position: relative;
						z-index: 2;
						display: inline-block;
						background-color: $color-white;
						padding: 0 1rem;
					}
				}

				.brands-list {
					display: flex;
					width: calc(100% + 1rem);
					margin: 1rem -1rem 0;
				}

					.list-brand {
						flex: 1 0 0;
						padding: 0 .5rem;
					}

						.brand-link {
							display: block;
							border: .1rem solid $color-gray-light;
							border-radius: $radius-general;
							padding: .5rem .8rem;
							transition: border-color $transition-hover ease;

							@include mediaMin {
								&:hover {
									border-color: $color-text-lighter;
								}
							}
						}

						.brand-imagewrap {
							position: relative;
							padding-bottom: 45%;
						}

						.brand-image {
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
						}
	}
}